nav {
  &.navbar {
    background-color: #0092a1;
    max-height: 60px;
    font-family: Platform-Medium, sans-serif;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 50px;

    a {
      &.nav-link {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
