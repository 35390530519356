@font-face {
  font-family: "Platform-Medium";
  src: url(../fonts/Platform-Medium.woff) format("woff");
}

@font-face {
  font-family: "Hind";
  src: url(../fonts/Hind.ttf) format("ttf");
}
// .row {
//     margin-right: 0;
//     margin-left: 0;
// }
html,
body,
.app {
  height: 100%;
  background: #fbfbfb;
  font-family: "Hind", sans-serif;
}
.hide {
  display: none;
}
#root {
  padding-bottom: 40px;
}
.btn-primary {
  background-color: #0093a1;
  border-color: #0093a1;
}
.app {
  position: relative;
  * {
    font-family: Hind, sans-serif;
    line-height: 1.1;
  }
  h1 {
    font-family: Platform-Medium, sans-serif;
    font-size: 21px;
  }
  h1 {
    font-size: 18px;
  }
  section {
    padding: 30px 0;
    &.title {
      padding-bottom: 20px;
      position: relative;
      margin-top: 40px;
      padding-top: 0;
    }
  }
}
.form-block {
  width: 410px;
  margin: 50px auto 0;
  background: #fff;
  padding: 50px;
  .error {
    color: #fff;
    background: red;
    margin-top: 10px;
    padding: 0 10px;
  }
}

.widget {
  background: #fff;
  border-radius: 5px;
  border: 2px solid #dadada;
  .details {
    padding: 10px 20px;

    p {
      margin-bottom: 0;
    }
  }

  &.survey-item {
    .widget-box {
      ul {
        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .widget-title {
    font-weight: bold;
    height: 70px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dadada;
    text-align: center;

    span {
      flex: 1;

      &.number {
        font-weight: normal;
        .head {
          display: block;
          font-size: 11px;
        }
      }
    }

    &.templates {
      justify-content: flex-start;
      padding-left: 10px;
    }
    .text-link {
      display: block;
      font-size: 14px;
      margin-bottom: 0;
      font-weight: normal;
      color: #0092a1;
      text-align: left;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .widget-box {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    ul {
      &.list {
        height: 223px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 8px 10px;
        border-bottom: 1px solid #dadada;
        cursor: pointer;
        position: relative;
        svg {
          font-size: 20px;
          margin-left: 10px;
          padding-top: 4px;
        }
        span {
          float: right;
          font-size: 13px;
          &.completion {
            position: absolute;
            top: 2px;
            right: 5px;
          }
        }

        &:hover {
          background: #f4f4f4;
        }
        // &:first-child {
        //   border-top: 1px solid #dadada;
        // }
        &:last-child {
          border-bottom: 0;
          margin-bottom: 20px;
        }
      }
    }
    .button-item {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-top: 1px solid #dadada;

      div {
        flex: 1;
        padding: 10px 0;
        cursor: pointer;
        &:first-child {
          border-right: 1px solid #f4f4f4;
        }
        &:hover {
          background: #fdfdfd;
        }
      }
    }
  }
}
.search-bar {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 0 50px;
  text-align: center;
  .suggestions {
    width: 100%;
    border: 2px solid #ced4d9;
    margin-top: 10px;
    max-height: 300px;
    overflow: scroll;
    z-index: 1000;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .search-suggestion {
      cursor: pointer;
      &:hover {
        background: #f4f4f4;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
}
.back-button {
  position: absolute;
  top: 2px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}
.page-header {
  padding-left: 80px;
  font-size: 24px;
  font-weight: bold;
}

.content {
  margin-top: 30px;
  .form-field {
    background: #fff;
    border: 1px solid #e4e3e3;
    padding: 20px;
    margin-bottom: 20px;
    min-height: 130px;
    position: relative;
    .input-submit {
      position: absolute;
      top: 15px;
      right: 20px;
      background: #2392a1;
      color: #fff;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: #187e8c;
      }
      &.disabled {
        opacity: 0.7;
        &:hover {
          background: #2392a1 !important;
          cursor: not-allowed;
        }
      }
    }

    .rdw-editor-main {
      background: #f4f4f4;
      padding: 10px 20px;
      max-height: 250px;
      overflow-y: scroll;
    }
    .input-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .sub {
      padding: 10px;
      background: #f4f4f4;
      margin: 20px 0px;

      cursor: pointer;
      .col-6 {
        flex: 0 0 48%;
        max-width: 48%;
        justify-content: center;
        align-items: center;
      }
      // .col-2 {
      //   display: flex;
      //   flex: 0 0 4%;
      //   max-width: 4%;
      //   justify-content: center;
      //   align-items: center;
      // }
      .actions {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        .add {
          color: green;
          font-size: 24px;
        }
      }
    }
  }
  .preview {
    background: #fff;
    border: 1px solid#e4e3e3;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}

.new-analysis {
  h1,
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  h1 {
    font-weight: bold;
  }
  .create {
    position: absolute;
    top: 0;
    right: 0;
  }

  .results {
    margin-top: 50px;
  }
  .checklist {
    margin-bottom: 40px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: flex;
      max-width: 80%;
      li {
        flex: 1;
        text-align: center;

        .icon {
          display: block;
          font-size: 32px;
        }
      }
    }
  }
  .selected-employees {
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      li {
        width: 100px;
        flex: 0 0 30%;
        margin: 0 0.5% 10px;
        padding: 10px 10px;
        background: #fff;
        border: 1px solid #ced4d9;
        overflow-wrap: break-word;
        position: relative;
        span.remove {
          position: absolute;
          right: 8px;
          top: 5px;
          font-size: 18px;
        }
        span.name,
        span.email {
          display: block;
        }
      }
    }
  }

  .form {
    max-width: 400px;
    margin: 0 auto;
    &.employees {
      max-width: 700px;
      margin-bottom: 30px;
    }
    &.images {
      .image-block {
        display: flex;
        flex-flow: wrap;
        .image {
          flex: 1 0 34%;
          margin: 5px 5px 25px;
          position: relative;
          padding: 10px 0;
          .img-icon {
            position: absolute;
            right: 7px;
            top: 2px;
            font-size: 21px;
            z-index: 9;
          }
          &.active {
            background: #e6e6e6;
          }
          img {
            width: 100%;
          }
        }
      }
    }

    input {
      margin-bottom: 10px;
    }
    .error {
      text-align: center;
      margin-bottom: 10px;
      background: #e93434;
      color: #fff;
    }

    .input-icon {
      position: absolute;
      right: 10px;
      top: 6px;
      svg {
        font-size: 25px;
        color: green;
      }
    }
    .slider-input {
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 100%; /* Full-width */
      height: 15px; /* Specified height */
      background: #d3d3d3; /* Grey background */
      outline: none; /* Remove outline */
      opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
      -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;
      padding: 0;

      &:hover {
        opacity: 1;
      }
      &:active,
      &:focus {
        border: 0 !important;
        background: #d3d3d3;
        box-shadow: none;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        border-radius: 50%;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #0093a1; /* Green background */
        cursor: pointer; /* Cursor on hover */
      }
      &::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #0093a1; /* Green background */
        cursor: pointer; /* Cursor on hover */
      }
    }
  }
}
.fa-times-circle {
  color: #e83434;
}
.fa-check-circle {
  color: #26cc33;
}

.button {
  background: #0d92a0;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;

  &.create,
  &.start {
    margin-top: 20px;

    &.next {
      float: right;
    }
    &.back {
      background: #e2e2e2;
      color: #333;
    }
  }

  &:hover {
    background: #0a7a86;
  }
}

.content-block {
  background: #fff;
  border: 1px solid #7777;
  padding: 40px;
  h2 {
    text-align: left;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 18px;
  }

  div {
    margin-bottom: 20px;
    span {
      display: block;
    }
  }
}

.title-block {
  background: #0d92a0;
  color: #fff;
  padding: 10px;

  h1 {
    margin: 0;
    font-size: 24px;
  }
}
.survey-block {
  background: #fff;
  border: 1px solid #7777;
  padding: 40px;
  position: relative;
  h2 {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.survey-item {
  background: #fff;
  border: 1px solid #ececec;
  margin-bottom: 20px;
  position: relative;

  .list-actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 10px;
    div {
      margin: 5px;
      padding: 5px;
      cursor: pointer;
      &.action {
        color: #555;
      }

      &.delete {
        color: #e64c4c;
      }
    }
  }

  h2 {
    font-size: 21px;
    margin-bottom: 10px;
    text-align: left;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      font-size: 13px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
.create-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 50px;
  color: #0092a1;
  z-index: 2;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    background: #fff;
    width: 86%;
    height: 38px;
    position: absolute;
    top: 18px;
    z-index: -1;
  }
}
.type {
  margin-top: 10px;

  .question-field {
    text-align: center;

    .form-group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 10px;
      padding: 20px;
      background: #ecfdff;

      .text {
        p {
          margin: 0;
        }
        &.left {
          text-align: right;
        }
        &.right {
          text-align: left;
        }
      }
      .form-check {
        flex: 1;
        &.bigger {
          flex: 1.5;
        }
        .form-check-input {
          display: block;
          position: unset;
          cursor: pointer;
          margin: 0 auto !important;
        }
      }
    }
  }
}
.select-template {
  .img-icon {
    position: absolute;
    right: 7px;
    top: 2px;
    font-size: 21px;
    z-index: 9;
  }
}
.progress-col {
  position: sticky;
  top: 0;
  padding: 10px 15px;
  z-index: 9999;
  background: #fbfbfb;

  .progress {
    margin-bottom: 10px;
    height: unset;

    .progress-bar {
      background-color: #2392a0;
    }
    span {
      padding: 5px 0;
      display: block;
    }
  }
}
.show-empty .empty .form-group {
  background: #ffefec;
  .form-check-label {
    background: #ffe3de !important;
  }
}
.form {
  .input-submit {
    background: #0093a1;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: #008490;
    }
  }
}
.big-text-labels {
  display: flex;

  h2 {
    cursor: pointer;
    flex: 1;
    text-align: center;
    border: 1px solid;
    margin: 0px 10px;
    padding: 8px;
    font-size: 15px !important;
  }
}
.widget-container {
  width: 100%;
}

.popup-container {
  position: fixed;
  background: #00000047;
  width: 100%;
  height: 100%;
  padding: 10px;
  top: 0;
  z-index: 999;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    margin: 0;
    max-width: 500px;
    background: #fff;
    width: 100%;
    height: auto;
    position: absolute;
    padding: 20px;
    top: 160px;
    .popup-title {
      margin: 0;
      font-weight: bold;
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
      cursor: pointer;
    }
    .input-list {
      max-height: 289px;
      overflow-y: auto;
      margin-bottom: 10px;
    }
  }
  .list-field {
    display: flex;
    span {
      flex: 1;
      &:first-child {
        flex: 0;
      }
    }
  }

  .form-check {
    padding: 0 1.25rem 5px;
    &.header {
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
}
.question-field {
  .form-check-label {
    width: 100%;
    background: #e2fcff;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #0000000a;
    cursor: pointer;
  }
}

.form-checkbox {
  .form-check-label {
    margin-bottom: 0;
    display: block;
  }
  .input-wrapper {
    display: block;
    position: relative;
    width: 100%;
  }

  .input-wrapper .form-check-input {
    margin: 0;
    cursor: pointer;
  }
}

.company-details {
  .category {
    padding: 20px;
    /* text-align: center; */
    .item {
      margin: 0 0 10px 0;
      display: block;
      background: #f4f4f4;
      padding: 6px;
      .average {
        font-size: 18px;
      }
    }
  }
}

.logo-image {
  margin-bottom: 30px;
  text-align: center;

  img {
    max-width: 200px;
  }
}
.link-text {
  display: block;
  width: 100%;
  text-align: center;
  color: #0093a1;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}
.notice {
  position: absolute;
  top: 70px;
  left: 20px;
  z-index: 9999;
  background: #0092a1;
  color: #fff;
  padding: 3px 20px;
  border-radius: 5px;
}
.skip-container {
  position: absolute;
  top: 20px;
  right: 40px;
  text-align: right;
  width: 200px;
  .skip-button {
    display: inline-block;
    margin-bottom: 10px;
  }
  small {
    display: block;
    font-size: 11px;
    width: 100%;
  }
}

.content-block {
  .matrix-image {
    width: 500px;
    background: #f1f1f1;
    height: 500px;
    position: relative;
    margin: 0 auto;
    .explainer {
      position: absolute;
      width: 140px;
      top: 40px;

      &.left {
        left: -160px;
        text-align: right;
      }

      &.right {
        right: -160px;
      }
    }
    .name {
      position: absolute;
      z-index: 111;
      background: #fff;
      padding: 4px 10px;
      border: 1px solid #0000006e;
      cursor: grab;
      &.react-draggable-dragging {
        cursor: grabbing;
      }
      &:hover {
        z-index: 999;
      }
    }

    .position {
      position: absolute;
      z-index: 99;
      background: rgb(255, 255, 255);
      width: 20px;
      height: 20px;
      text-align: center;
      bottom: 37%;
      left: 80%;
      border-radius: 50%;
      border: 1px solid #80808085;
      margin-bottom: -15.5px;
      margin-left: -17.5px;

      &.active-drag {
        border: 2px solid #9c9c9c;
        z-index: 9999999;
        background: #9c9c9c;
      }
    }

    // left top
    .directing {
      display: flex;
      left: 0;
      position: absolute;
      top: 0;
      width: 248px;
      height: 248px;
      background: #e02712;
    }

    // left bottom
    .exploring {
      display: flex;
      left: 0;
      position: absolute;
      bottom: 0;
      width: 248px;
      height: 248px;
      background: #0d92a0;
    }

    // right top
    .conducting {
      display: flex;
      right: 0;
      position: absolute;
      top: 0;
      width: 248px;
      height: 248px;
      background: #580e03;
    }

    // right bottom
    .architecting {
      display: flex;
      right: 0;
      position: absolute;
      bottom: 0;
      width: 248px;
      height: 248px;
      background: #02393e;
    }
  }
}
@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
